<template>
    <div>
        <div class="input-group  mb-2">
            <span class="input-group-text">Filter</span>
            <select class="form-select" v-model="showStatus">
                <option value="all">All</option>
                <option value="active">Only Active</option>
                <option value="inactive">Only Inactive</option>
            </select>
        </div>
        <hr>
        <div class="scheduled-meals-container gap-4">
            <recipe-inline-editor 
                v-for="(recipe, index) in filteredRecipes" 
                :key="recipe.id"
                :recipe="recipe"
                @toggleActive="toggleActiveState($event, index)"
                class="mb-3"
                :sections="sections"
                @setType="setTypeForRecipe($event, recipe)"
            ></recipe-inline-editor>
        </div>
    </div>
</template>

<script>

import RecipeInlineEditor from '../Components/RecipeInlineEditor.vue';

export default {
    components: { RecipeInlineEditor },
    props : ['recipes', 'sections'],
    data(){
        return {
            myRecipes : this.recipes,
            showStatus : 'active'
        }
    },
    mounted(){

    },
    computed : {
        filteredRecipes(){
            if (this.showStatus == 'all'){
                return this.myRecipes
            } else if (this.showStatus == 'active') {
                return this.myRecipes.filter( (r) => (r.active || r.wasJustChanged));
            } else {
                return this.myRecipes.filter( (r) => (!r.active || r.wasJustChanged));
            }
        }
    },
    methods : {
        toggleActiveState(recipe, index){
            recipe.wasJustChanged = true;
            recipe.active = !recipe.active

            let active = recipe.active;
            window.axios.post(`/api/recipes/${recipe.id}`, {
                _method : "PUT",
                active
            })
        },
        updateRecipe(recipe){
            window.axios.post(`/api/recipes/${recipe.id}`, {
                ...recipe,
            })
        },
        setTypeForRecipe(type, recipe){
            window.axios.post(`/api/recipes/${recipe.id}`, {
                _method: "PATCH",
                type
            })
        }
    }
}
</script>

<style>
</style>