<template>
    <div class="card">
        <div class="card-header d-flex align-items-center">
            <h3 class="mb-0">
                {{textTransform(recipe.name, 'title')}}
            </h3>
            <div class="ms-auto d-flex">
                <div class="form-check form-switch">
                    <input 
                        class="form-check-input"
                        type="checkbox"
                        role="switch"
                        id="flexSwitchCheckDefault"
                        :checked="recipe.active"
                        @change="toggleActiveState(recipe)"
                    >
                    <label class="form-check-label" for="flexSwitchCheckDefault">
                        <span v-text="recipe.active ? 'Active' : 'Inactive'"></span>
                    </label>
                </div>
                <a class="ms-4 text-decoration-none" :href="`/recipes/${recipe.id}/edit`">Edit</a>
            </div>
        </div>
        <div class="card-body">
            <div class="input-group">
                <span class="input-group-text">Set Type</span>
                <select class="form-select" :value="recipe.type" @change="setType">
                    <option value="Main">Main</option>
                    <option value="Side">Side</option>
                    <option value="Takeout">Takeout</option>
                </select>
            </div>
        </div>
            <ul class="list-group list-group-flush">
                <li class="list-group-item"
                    v-for="ingredient in recipe.ingredients"
                    :key="ingredient.id+'-'+recipe.id"
                >
                <div class="row">
                    <div class="col-8 d-flex align-items-center">
                        {{textTransform(ingredient.name, 'title')}}
                    </div>
                    <div class="col-4">
                        <select @change="updateIngredient($event, ingredient)" class="form-select ms-auto" :value="ingredient.grocery_store_section">
                            <option 
                                v-for="section in sections"
                                :key="section+'+'+ingredient.id">{{section}}
                            </option>
                        </select>
                    </div>
                </div>
                </li>
                <a v-if="recipe.type !== 'Takeout'" class="list-group-item" :href="`/recipes/${recipe.id}/edit`">Edit Ingredients</a>
            </ul>
    </div>
</template>

<script>
export default {
    props : ['recipe', 'sections'],
    data(){
        return {
        }
    },
    mounted(){

    },
    methods : {
        toggleActiveState(recipe){
            this.$emit('toggleActive', (recipe));
        },
        setType(event){
            this.$emit('setType', (event.target.value))
        },
        updateIngredient(event, ingredient){
            let grocery_store_section = event.target.value;
            window.axios.post(`/api/ingredients/${ingredient.id}`, {
                _method : "patch",
                grocery_store_section
            });
        }
    }
}
</script>

<style>
</style>