<template>
    <div class="card">
        <div class="card-header text-white bg-secondary d-flex align-items-center">
            <h5 class="card-title mb-0" style="font-size:22px;">{{myWeekDay}}</h5>
            <h6 class="card-title mb-0 ms-auto">{{myWeekDate}}</h6>
        </div>

        <ul class="list-group list-group-flush">
            <li 
                class="list-group-item d-flex align-items-center"
                :class="{'leftovers' : recipe.is_leftovers}"
                v-for="recipe in myMeal.recipes" :key="recipe.id"
            >
                <div>
                    {{textTransform(recipe.name, 'title')}} (<b>{{recipe.type}}</b>)
                </div>
                <div class="ms-auto">
                    <button 
                        class="btn btn-sm " 
                        @click="toggleIsLeftovers(recipe)"
                        :class="{
                            'btn-secondary' : recipe.is_leftovers,
                            'btn-outline-primary' : !recipe.is_leftovers
                        }"
                    >
                        <span v-if="!recipe.is_leftovers">Fresh</span>
                        <span v-else>Leftovers</span>
                    </button>
                    <button class=" btn btn-sm btn-outline-danger" @click="removeRecipe(recipe)">Remove</button>
                </div>
            </li>
            <li v-if="myMeal.recipes.length == 0 " class="list-group-item text-muted">No meals scheduled yet</li>
            <li class="list-group-item">
                <div class="input-group">
                    <span class="input-group-text">Add Recipe:</span>
                    <select @change="addRecipe" class="form-select form-select-sm " v-model="recipe_to_add">
                        <optgroup label="Mains">
                            <option 
                                v-for="recipe in myMeal.available_recipes.Main"
                                :key="recipe.id+'-'+myMeal.date"
                                :value="recipe.id"
                                :disabled="scheduledRecipeIds.includes(recipe.id)"
                            >
                                {{textTransform(recipe.name, 'title')}}
                            </option>
                        </optgroup>
                        <optgroup label="Sides">
                            <option 
                                v-for="recipe in myMeal.available_recipes.Side"
                                :key="recipe.id+'-'+myMeal.date"
                                :value="recipe.id"
                                :disabled="scheduledRecipeIds.includes(recipe.id)"
                            >
                                {{textTransform(recipe.name, 'title')}}
                            </option>
                        </optgroup>
                        <optgroup label="Takeout">
                            <option 
                                v-for="recipe in myMeal.available_recipes.Takeout"
                                :key="recipe.id+'-'+myMeal.date"
                                :value="recipe.id"
                                :disabled="scheduledRecipeIds.includes(recipe.id)"
                            >
                                {{textTransform(recipe.name, 'title')}}
                            </option>
                        </optgroup>
                    </select>
                </div>
            </li>
        </ul>
        
    </div>
</template>

<script>
import moment from 'moment';

export default {
  components: {  },
    props : ['mealday'],
    data(){
        return {
            recipe_to_add : '',
            myMeal : this.mealday
        }
    },
    computed : {
        scheduledRecipeIds(){
            return this.myMeal.recipes.map( (rec) => rec.id);
        },
        myWeekDay(){
            return moment(this.myMeal.meal_date).format('dddd');
        },
        myWeekDate(){
            return moment(this.myMeal.meal_date).format('MMM Do');
        }
    },
    mounted(){

    },
    methods : {
        addRecipe(){
            let recipePost = {
                day : this.myMeal.meal_date,
                recipe_id : this.recipe_to_add
            }
            this.recipe_to_add = '';

            window.axios.post("/api/scheduled-meals", recipePost)
            .then( (response) => {
                var recipe = response.data;
                recipe.is_leftovers = false;
                this.myMeal.recipes.push(recipe);
                this.myMeal.recipes = this.myMeal.recipes.sort((a,b) => {
                    var textA = a.name.toUpperCase();
                    var textB = b.name.toUpperCase();
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                })
            })
        },
        removeRecipe(recipe){
            let url = `/api/scheduled-meals/${this.mealday.id}/recipe/${recipe.id}`;
            this.myMeal.recipes = this.myMeal.recipes.filter( (rec) => {
                return rec.id !== recipe.id
            })
            window.axios.post(url, {_method: "DELETE"}).then( (response) => {})
        },
        toggleIsLeftovers(recipe){
            let url = `/api/scheduled-meals/${this.mealday.id}/recipe/${recipe.id}/leftovers`;
            recipe.is_leftovers = !recipe.is_leftovers
            window.axios.post(url, {
                is_leftovers: recipe.is_leftovers
            });
        }
    }
}
</script>

<style scoped>
    .leftovers { 
        background: rgb(246, 241, 255);
        border-color: rgb(208, 179, 255);
    }
</style>