
require('./bootstrap');

import Vue from 'vue';

Vue.component('scheduled-meal-editor', require('./Components/ScheduledMealEditor.vue').default);
Vue.component('recipe-inline-editor', require('./Components/RecipeInlineEditor.vue').default);
Vue.component('recipes-page', require('./Pages/Recipes.vue').default);



import { textTransform } from 'text-transform';

Vue.mixin({
    methods: {
        textTransform(text, type){
            return  textTransform(text, type)
        }
    }
})

const app = new Vue({
    el: '#app'
});